<template lang="pug">
.campaign-flow-new
  Layout(@back="navigateBack")
    Branching(@select="onSelect")
</template>

<script>
  import { track } from '@/services/xray';

  const ucFirst = (str) => `${str.charAt(0).toUpperCase()}${str.substring(1)}`;

  export default {
    components: {
      Branching: () => import('@/components/NewCampaignFlow/contents/Branching.vue'),
      Layout: () => import('@/components/NewCampaignFlow/Layout.vue'),
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        const { initiator = null } = vm.$route.query;
        vm.initiator = !initiator ? from?.name ?? from.fullPath : initiator;
      });
    },
    data: () => ({ initiator: null, component: 'branching' }),
    computed: {
      isBranching() {
        return this.$route.name === 'new_campaign_flow';
      },
    },
    watch: {
      initiator(value, old) {
        if (value && old !== value) {
          this.setQueryStringParameter('initiator', value);
        }
      },
    },
    mounted() {
      track('newCampaignFlow-visit');
    },
    methods: {
      setQueryStringParameter(name, value) {
        const params = new URLSearchParams(window.location.search);
        params.set(name, value);
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
      },
      navigateBack() {
        if (this.isBranching) {
          this.onTrack({ name: 'leave', properties: { to: this.initiator } });
          return this.$router.push({ name: this.initiator });
        }
        this.onTrack({ name: 'back' });
      },
      onSelect(value) {
        this.onTrack({ name: 'select', properties: { value } });
        const method = `navigate${ucFirst(value)}`;
        this?.[method]?.();
      },
      onTrack({ name, properties }) {
        track(`newCampaignFlow-${name}`, properties);
      },
      navigatePopup() {
        this.$router.push({ name: 'templates' });
      },
      navigateOptimize() {
        this.$router.push({
          name: 'new_campaign_flow_optimize',
          query: { initiator: this.initiator },
        });
      },
      navigateLibrary() {
        const url =
          this.$i18n.locale === 'hu'
            ? 'https://library.optimonk.hu/templates/use-cases'
            : 'https://library.optimonk.com/templates/use-cases';
        const tab = window.open(url);
        tab.focus();
      },
    },
  };
</script>
